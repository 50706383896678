<template>
  <div class="UserAgreement">
    <div class="title">
      <!-- {{ $t('App.Agreement') }} -->
      <div style="width: 0.18rem; height: 0.31rem"></div>
    </div>
    <enAgreement />
  </div>
</template>
<script>
import enAgreement from './launge/enAgreement.vue'
export default {
  name: 'HomeAgreement',
  components: {
    enAgreement
  },
  data () {
    return {
      selLocale: ''
    }
  },
  created () {
    this.selLocale = this.$i18n.locale = this.$route.query.lang || 'en'
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-hairline--bottom::after {
  border: none;
}
.UserAgreement {
  .title {
    height: 0.88rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.34rem;
    img {
      width: 0.18rem;
      height: 0.31rem;
    }
  }
  background: #000000;
  flex: 1;
  overflow: scroll;
  padding: 0rem 0.42rem;
}

</style>
